import React from 'react';

const ArrowRight = () => (
	<svg
		width="7px"
		height="12px"
		viewBox="0 0 7 12"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round">
			<g id="checkout" transform="translate(-49.000000, -30.000000)" stroke="#FFFFFF">
				<g id="Group-6" transform="translate(0.000000, 28.000000)">
					<g id="right_arrow_checkout" transform="translate(49.000000, 2.000000)">
						<polyline id="Path" points="0.5 0.5 6.5 6 0.5 11.5"></polyline>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default ArrowRight;
