import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'gatsby';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions, { defaultWidth } from '../styles/dimensions';
import content from '../content/thankYou/page.json';
// @ts-ignore
import LogoImg from '../images/Easyfit_logo.svg';

const ThankYou = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.contentContainer}>
						<img src={LogoImg} alt={content.logo.alt} className={classes.logo} loading="lazy" />
						<h2 className={classes.header}>{content.title}</h2>
						<Link to={content.button.src}>
							<div className={classes.offerContainer}>
								<span className={classes.offerText}>{content.button.text}</span>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ThankYou;

const useClasses = makeStyles({
	section: {
		backgroundColor: Colors.backgroundDarkLimeGreen,
	},
	container: {
		backgroundImage: `url(${require(`../images/offer/${content.backgroundImage.src}`)})`,
		backgroundPositionX: '100%',
		backgroundPositionY: '5%',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		'@media (max-width: 1231px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'@media (max-width: 959px)': {
			backgroundImage: `url(${require(`../images/offer/${content.backgroundImageTablet.src}`)})`,
			backgroundPositionY: 'center',
		},
	},
	innerContainer: {
		maxWidth: defaultWidth,
		margin: '0 auto',
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
		'@media (min-width: 600px)': {
			maxWidth: '50%',
		},
	},
	header: {
		whiteSpace: 'pre-wrap',
		color: Colors.white,
		textAlign: 'center',
		marginTop: 0,
		marginBottom: '1.1em',
		fontSize: Dimensions.smallTitle.fontSize,
		lineHeight: Dimensions.smallTitle.lineHeight,
		'@media (max-width: 959px)': {
			marginTop: '0.9em',
			marginBottom: '1em',
			fontSize: Dimensions.largeText.fontSize,
			lineHeight: Dimensions.largeText.lineHeight,
		},
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularText.fontSize,
			lineHeight: Dimensions.regularText.lineHeight,
			marginTop: '1.5em',
			marginBottom: '1.6666666666666667em',
		},
	},
	offerContainer: {
		backgroundColor: Colors.limeGreen,
		borderRadius: '8px',
		padding: '0.875em 2.25em',
	},
	offerText: {
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.headerButtonBlack,
	},
	logo: {
		maxWidth: '216px',
		'@media (min-width: 960px)': {
			display: 'none',
		},
	},
});
