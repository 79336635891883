import React from 'react';

const CheckboxMarked = () => (
	<svg
		width="12px"
		height="12px"
		viewBox="0 0 12 12"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g
			id="UI"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round">
			<g id="Payment---SWIFT-+-Paypal" transform="translate(-428.000000, -1096.000000)" stroke="#FFFFFF">
				<g id="Group-11" transform="translate(379.000000, 1093.000000)">
					<g id="checkbox_marked" transform="translate(49.000000, 3.000000)">
						<rect id="Rectangle" x="0.5" y="0.5" width="11" height="11" rx="1"></rect>
						<polyline id="Path" points="3 6.5 5 8.5 9 3.5"></polyline>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default CheckboxMarked;
